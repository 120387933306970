import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation } from "react-router-dom";


import './css/Tasks.css'
import { AppContext } from "../Context";
import Fetch, { Loader } from '../inc/Fetch'
import { Games } from "../components/Elements";
import { Input, MultiSelect, ImageUpload } from "../components/Form";

import Footer from "./Footer";
import Header from "./Header";

import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { GiShare } from "react-icons/gi";
import { IoIosAdd } from "react-icons/io";


export default function Cp() {

	const active = useRef(true);
	const {success, userinfo, showQuiz, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [showQuizD, setshowQuizD] = showQuiz;
	const [text, settext] = languageText;

	const [pageLoad, setpageLoad] = useState(true); 
	const [data, setdata] = useState([]); 
	const [filter, setfilter] = useState({}); 
	const [cats, setcats] = useState([]); 
	const [cls, setcls] = useState([]); 
	

	
	useEffect(() => {

		if (text?.all_categories) {
		if (active.current != filter) {
			active.current = filter;

			setpageLoad(true);

			Fetch("Games/viewCats", (d) => {

				if (!d) {
					setopen({
						title:text?.alertErrorTitle,
						short:text?.cant_get_data,
						error:true
					});
				} else {


					let arrCats = [{ name: `- جميع الاقسام -`, val:false}];
					d?.map((v) => {
						arrCats.push({ name: v?.name, val:v?._id});
					});
					
					setcats(arrCats);

					let arrClas = [{ name: `- جميع الصفوف -`, val:false}];
					Object.keys(global.Classes)?.map((v) => {
						arrClas.push({ name: global.Classes[v], val:v});
					});
					setcls(arrClas);

					
					Fetch("Games/view", (d) => {

						if (!d) {
							setopen({
								title:text?.alertErrorTitle,
								short:text?.cant_get_data,
								error:true
							});
						} else {
							
							setdata(d);
		
						}
		
						setpageLoad(false);
					}, { filter:filter});

				}

			});
		
		}
		}
	}, [filter, text?.all_categories]);

	

	return (
		
		<>
			
			<Header />
			<div className='firstElem search'>
				<div className='mainWidth'>

					<div className='filterBoxTop'>

						<Input 
                            type="select"
                            title="" 
                            name="level"
                            validate={true}
                            values={cats}
                            onChange={(v) => setfilter(p => ({
                                ...p,
                                cats:v,
                            }))}
                            value={filter?.cats}
                        />

						<Input 
                            type="select"
                            title="" 
                            name="level"
                            validate={true}
                            values={cls}
                            onChange={(v) => setfilter(p => ({
                                ...p,
                                classes:v,
                            }))}
                            value={filter?.classes}
                        />
			


					</div>

				</div>
			</div>


			<section style={{backgroundColor:"transparent"}} className='sect5 tasks gamesCont'>

				{pageLoad ? <Loader /> :

				<div className='mainWidth' style={{backgroundColor:"#fff"}} >
					<h4 className='boxTitle' style={{margin:"20px 0"}}>قائمة الالعاب</h4>
					
					<div className='sect5Box '>
						{data?.length ? data?.map((v, k) => {
							
							return (
								<Games key={k} data={v} />
							);
						})
						
						:
							<div></div>
						}

					</div>
					
					
				</div>}
			</section>

			<Footer />
		</>
	)
}
