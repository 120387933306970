import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Fetch, { Loader } from '../inc/Fetch'
import { AppContext } from "../Context";

import Dictionary from "../pages/popups/Dictionary";


import { MdOutlineLockOpen, MdOutlineFavoriteBorder, MdOutlineTranslate } from "react-icons/md";
import { RiProfileLine, RiListCheck2, RiLockFill } from "react-icons/ri";
import { PiStudentBold } from "react-icons/pi";
import { FiActivity, FiSend } from "react-icons/fi";
import { GoGear, GoGitPullRequest } from "react-icons/go";
import { IoHome, IoChatbubbleOutline, IoHomeOutline, IoGameControllerOutline, IoCallOutline, IoPersonOutline, IoTrendingUp } from "react-icons/io5";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaRegEye, FaChalkboardTeacher } from "react-icons/fa";



export function MainMenu() {

    let params = useParams();
    let loc = useLocation();

    
    
    const {success, userinfo, language, languageText, loginForm} = useContext(AppContext);
    const [logedIn, setlogedIn] = userinfo;
    const [lang, setlang] = language;
    const [text, settext] = languageText;
    const [openLogin, setopenLogin] = loginForm;

    return (
        <>
            <Link to='/' className={loc?.pathname == "/" ? "checked" : 'none'}><IoHomeOutline /> البيت</Link>
            <Link to="/Games" className={loc?.pathname == "/Games" ? "checked" : 'none'}><IoGameControllerOutline /> العاب</Link>
            {logedIn?.userinfo?.userlevel >= 3 && <Link onClick={() => !logedIn?.userinfo?._id && setopenLogin(true) } to={logedIn?.userinfo?._id && '/Tasks'} className={loc?.pathname == "/Tasks" ? "checked" : 'none'}><IoMdInformationCircleOutline />الدروس</Link>}
            <Link to="/Article/6790a8cbe8002906b67b149e" className={loc?.pathname == "/Article/6790a8cbe8002906b67b149e" ? "checked" : 'none'}><IoPersonOutline /> من نحن</Link>
            <Link to="/Article/6790a915e8002906b67b14de" className={loc?.pathname == "/Article/6790a915e8002906b67b14de" ? "checked" : 'none'}><IoTrendingUp /> رؤيتنا</Link>
            <Link to="/Article/6790a944e8002906b67b1505" className={loc?.pathname == "/Article/6790a944e8002906b67b1505" ? "checked" : 'none'}><IoCallOutline /> تواصلوا معنا</Link>
        </>
    );
}

export function MainUserMenu({setopenDic}) {

    const loc = useLocation();
    const navigate = useNavigate();

    const {success, userinfo, languageText, showsendTask, cartData} = useContext(AppContext);
    const [open, setopen] = success;
    const [logedIn, setlogedIn] = userinfo;
    const [text, settext] = languageText;
    const [showsendTasks, setshowsendTasks] = showsendTask;
    const [cart, setcart] = cartData; 

    function logOut() {
        setlogedIn({});
        Fetch("logout", (d) => {
            setopen({
                title:"احسنت",
                short:`لقد قمت بالخروج من الموقع بنجاح.`,
                // redirect:"/"
            });

            navigate("/");
        });
    }


    return (
        <>

            <ul className='userMenuBox'>
                {logedIn?.userinfo?.userlevel >= 3 ? 
                <>
                    <Link to='/Cp'><GoGear />لوحة التحكم</Link>
                    <Link to='/Profile'><RiProfileLine />حسابي</Link>
                    <Link onClick={() => setshowsendTasks({questions: cart})}><MdOutlineFavoriteBorder />اسئلتي المفضلة ({cart?.length})</Link>
                    {/* <Link to='/Questions'><FiSend />{text?.questions_new_task}</Link> */}
                    <Link to='/Tasks'><FiSend />مهمة جديدة</Link>
                    <Link to='/MyTasks'><RiListCheck2 />مهامي المحوسبة {logedIn?.tasks_num ? `(${logedIn?.tasks_num})` : false}</Link>
                    <Link to='/Students'><PiStudentBold />ادارة الطلاب</Link>
                    <Link onClick={logOut}><RiLockFill />الخروج</Link>
                </>
                :
                <>
                    <Link to='/Cp'><GoGear />لوحة التحكم</Link>
                    <Link to='/Profile'><RiProfileLine />حسابي</Link>
                    {/* <Link to='/Training'><FaChalkboardTeacher />Training</Link> */}
                    <Link to='/MyTasks'><RiListCheck2 />مهامي المحوسبة {logedIn?.tasks_num ? `(${logedIn?.tasks_num})` : false}</Link>
                    <Link onClick={logOut}><RiLockFill />الخروج</Link>
                </>
                }
                
            </ul>
        </>
    );
}