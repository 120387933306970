import { Config } from './Config';
import '../App.css';
import Cookies from 'js-cookie';
// import {  } from 'react';

export default async function Fetch(url, _callback, post) {

    if (!post) {
        post = {};
    }
    if (!post?.subject) {
        post.subject = "678cdd9d3887cedb9f8546f9";
    }

    const requestOptions = {
        method: 'POST',
        credentials : "include",
        signal: AbortSignal.timeout(30000),
        headers: {  
            'Content-Type': 'application/json', 
        },
        body: JSON.stringify(post)
    };
    
    try {

        const res = await fetch(Config.domain+url, requestOptions);
        if (res.status != 200) {

            console.log(res.status);
            
            if(res.status === 400){
                _callback({error: "no data"});
            } else if(res.status === 401){ 
                _callback({error: "login"});
            } else if(res.status === 404){ 
                _callback({error: "not found"});
            } else {
                
                _callback({error: res});
            }

        } else {

            const data = await res?.json();
            if (data.error) {
                _callback({error: data.error});
            } else {
                if (typeof _callback == 'function'){
                    _callback(data);
                }
            }
        }
    } catch(e) {
        console.log(e);
        _callback({error: "error"});
    }
}



export function Loader({styles}) { 
    return (
        <div style={styles} className='LoaderBox'>
            <span className="loader"></span>
        </div>
    );
}

export function IsJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return JSON.parse(str);
}


export async function UploadFile(file) { 

    async function upload(base) { 

        var reader = new FileReader();

        return new Promise(resolve => {
            reader.onload = ev => {
              resolve(reader.result)
            }
            reader.readAsDataURL(base)
        })
    }

    let arr = [];

    if (file.target.files?.length) {

        await Promise.all(
            Array.from(file.target.files)?.map(async (v, k) => {

                let splitFormatsAllowed = Config?.formatAllowed?.split(",");
                let ck = splitFormatsAllowed?.filter(r => r == v?.type);
                if (ck?.length) {
                    let r = await upload(v);
                    arr.push(r);
                } else {
                    arr = { error: "Image format is not allowed!!" };
                }
            })
        )

    }

    return arr;

    
}

export function Image({src, style}) {
    if (!src) {
        return (
            <img src="/Files/nopic.jpg" style={style} />
        );
    } else {
        return (
            <img src={src} style={style} />
        );
    }
    
}

export function dateToText(time, type) {
    

    const now = Date.now();

    let timer = (now - time);
    let diff = parseInt(timer / 60 / 1000);   
    if (isNaN(diff)) {
        timer = (now - new Date(time).getTime());
        diff = parseInt(timer / 60 / 1000);
    }

    let formattedDate;

    if (type == "date") {
        const date = new Date(time);
        formattedDate = date.toLocaleDateString('he-IL', { year: 'numeric', month: '2-digit', day: '2-digit'});
        return formattedDate;
    } else if (type == "datetime") {

        const date = new Date(time);
        formattedDate = date.toLocaleDateString('he-IL', { year: 'numeric', month: '2-digit', day: '2-digit', hour12: false, hour: "2-digit", minute: "2-digit" });
    
        return formattedDate;
    }

    else if (type == "time") {

        const date = new Date(time);
        formattedDate = date.toLocaleDateString('he-IL', { hour:"2-digit", minute: "2-digit" });
    
        return formattedDate;
    }
    
    if (diff < 0) {

        const date = new Date(time);
        formattedDate = date.toLocaleDateString('he-IL', { year: 'numeric', month: '2-digit', day: '2-digit', hour12: false, hour: "2-digit", minute: "2-digit" });
    
    } else {
        
        const date = new Date(time);
        formattedDate = date.toLocaleDateString('he-IL', { year: 'numeric', month: '2-digit', day: '2-digit', hour12: false, hour: "2-digit", minute: "2-digit" });

        if (diff < 1) {
            formattedDate = "قبل قليل";
        } else if (diff < 60 && diff >= 1) {
            formattedDate = `قبل ${diff} دقيقة`;
        } else if (diff >= 60 && diff < 1440) {
            formattedDate = `${parseInt(timer / 60 / 60 / 1000)} Hours ago`;
        }

        // console.log(diff);
    }
    
    

    return formattedDate;
}

export function PlaySound(text) {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);
    // u.voice = synth.getVoices()[1];
    u.rate = 0.8;
    synth.speak(u);
}