import { useEffect, useState, useContext, useRef} from 'react'
import {  Link } from "react-router-dom";
import TextTruncate from 'react-text-truncate';
import {Helmet} from "react-helmet";

import './css/Home.css'
import manSrc from '../images/man.png'
import stSrc from '../images/st3.png'
import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";
import { Img, Tasks, Games } from "../components/Elements";

import Footer from "./Footer";
import Header from "./Header";
import Dictionary from "./popups/Dictionary";



export default function Home() {

	const {success, userinfo, languageText, language} = useContext(AppContext);
	const [open, setopen] = success;
	const [text, settext] = languageText;
	const [lang, setlang] = language;

	const [data, setdata] = useState({});
	const [quiz, setquiz] = useState([]);
	const [games, setgames] = useState([]);
	const [dc, setdc] = useState([]);
	const [loader, setloader] = useState(true);
	const [openDic, setopenDic] = useState(false);

	useEffect(() => {
		// setloader(true);
		Fetch("Home", (d) => {
			
			if (!d?.error) {
				setdata(d);
			}

			setloader(false);
		});
		
	}, []);

	if (loader) {
		return (<Loader />);
	}


	return (
		
		<>
			{openDic && <Dictionary open={openDic} setopen={setopenDic} />}

			<Helmet>

                <title>موقع العربية</title>

            </Helmet>
			<Header data={data?.news} />

			<div className='body'>
				

				<section className='sect5'>
					<div className='mainWidth'>

						<div style={{marginBottom:50, width:"100%"}}>
							<h3 className='boxTitle'>احدث الدروس المحوسية</h3>
							<p className='boxshort'>دروس عبر الإنترنت باستخدام التكنولوجيا التفاعلية</p>
							<hr className='boxLine'/>
						</div>
						
						<div className='sect5Box'>
							{data?.quiz?.length ? data?.quiz?.map((v, k) => {
								return (
									<Tasks data={v} key={k} />
								);
							}) : false }
							
						</div>
						
						
					</div>
				</section>


				<section className='sect5 gray5'>
					<div className='mainWidth'>

						<div style={{marginBottom:50, width:"100%"}}>
							<h3 className='boxTitle'>العابنا التعليمية</h3>
							<p className='boxshort'>تقدم بيئة مشوقة تحفز الأطفال والكبار على التفاعل والمشاركة.</p>
							<hr className='boxLine'/>
						</div>
						
						<div className='sect5Box'>
							{data?.games?.length ? data?.games?.map((v, k) => {
								return (
									<Games data={v} key={k} />
								);
							}) : false }
							
						</div>
						
					</div>
				</section>


			</div>

			<div className='statsMainBox'>
				<div className='mainWidth'>
					<div>
						<b>+ 34K</b>
						<span>عدد المدارس المشتركة</span>
					</div>

					<div>
						<b>+ 12K</b>
						<span>عدد الطلاب والمعلمين</span>
					</div>

					<div>
						<b>+ 2K</b>
						<span>الدروس المحوسبة</span>
					</div>

					<div>
						<b>+ 28K</b>
						<span>عدد المهام المرسلة للطلاب</span>
					</div>
				</div>
			</div>

			<Footer />
		</>
	)
}
